import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"
import "../components/singlepage.scss"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>Sembra non ci siano post ancora</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
        description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
      />
      <div className="fix-content-width">
        <NavBar />
        <div className="singlepage-container">
          <div className="section-subtitle singlepage-title">
            <h2
              data-aos="custom-fade"
              data-aos-duration="1000"
              style={{ color: "#121212" }}
            >
              <span style={{ fontWeight: 700, textDecoration: "italic" }}>
                Blog
              </span>
            </h2>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="singlepage-title-border"
            ></div>
          </div>
          <ol
            style={{ listStyle: `none`, margin: 0, paddingTop: "32px" }}
            className="paddings-lr postlist-container"
          >
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug

              return (
                <li key={post.fields.slug} className="postlist-li">
                  <Link to={post.fields.slug} itemProp="url">
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <header style={{ paddingBottom: "16px" }}>
                        <h2 style={{ fontSize: "20px" }}>
                          <span itemProp="headline">{title}</span>
                        </h2>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "200",
                            letterSpacing: "5%",
                            textTransform: "uppercase",
                          }}
                        >
                          {post.frontmatter.date}
                        </span>
                      </header>
                      <section>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                          style={{ fontSize: "15px" }}
                        />
                      </section>
                    </article>
                  </Link>
                  <br />
                  <div
                    style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)" }}
                  ></div>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
      <Contact />
      <Footer />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

/* import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"

import "../styles/animations.scss"
import "../components/singlepage.scss"

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  console.log(posts)

  return (
    <Layout>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
        description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
      />

      <div className="fix-content-width">
        <NavBar />
        <div className="singlepage-container">
          <div className="section-subtitle singlepage-title">
            <h2
              data-aos="custom-fade"
              data-aos-duration="1000"
              style={{ color: "#121212" }}
            >
              <span style={{ fontWeight: 700, textDecoration: "italic" }}>
                Blog
              </span>
            </h2>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="singlepage-title-border"
            ></div>
          </div>
          {posts.map((e, index) => {
            return (
              <Link to={`/${e.frontmatter.title}`} key={index}>
                <div className="blogpage-card-container">
                  <div className="blogpage-card">{e.frontmatter.title}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
      <Contact />
      <Footer />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
 */
